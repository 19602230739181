import { useAmp } from 'next/amp'
import { useEffect } from 'react'

import useOnMouseAndScroll from '@shared/hooks/useOnMouseAndScroll'
import { injectScript } from '@shared/utils'

export default function ComScoreAnalytics() {
  const isAmp = useAmp()
  const { isLoaded } = useOnMouseAndScroll()
  useEffect(() => {
    if (!isAmp && isLoaded) {
      injectScript('https://sb.scorecardresearch.com/cs/40049797/beacon.js').then(() => {
        if (window._comscore) {
          window._comscore.push({ c1: '2', c2: '40049797', options: { enableFirstPartyCookie: 'false' } })
        }
      })
    }
  }, [isLoaded])
  return null
}
